import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import RestaurantBanner from "../assets/images/RestaurantBanner.png";
import {
  fetchStoreDetails,
  getApitoken,
  getItemsGroup,
} from "../module/landingScreen";
import Footer from "../components/Footer";
import HeaderInnerComp from "./Header";
import NoImgFood from "../assets/images/nofood-img-dummy.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ItemDetails() {
  const { cost_center_name, custom_address, custom_location, itemId } =
    useParams();
  const location = useLocation();
  const navigate = useNavigate(); // useNavigate hook for navigation
  const outlet = location.state?.outlet;

  const [itemDetails, setItemDetails] = useState(null);
  const [apiToken, setApiToken] = useState(null);
  const [relatedItems, setRelatedItems] = useState([]);
  const [storeDetails, setStoreDetails] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [selectedRelatedItemId, setSelectedRelatedItemId] = useState(null);
  const [selectedRelatedItemDetails, setSelectedRelatedItemDetails] = useState(
    []
  );
  const [selectedSubRelatedItem, setSelectedSubRelatedItem] = useState(null); // New state for selected sub-related item
  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });

  const getAPIToken = async () => {
    try {
      const res = await getApitoken();
      if (res.status === 200) {
        const apiToken = res.data.message.data.api_token;
        setApiToken(apiToken);
      } else {
        console.error("Failed to fetch API token:", res.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getAPIToken();
  }, []);

  const fetchItemDetails = async () => {
    try {
      const response = await getItemsGroup(apiToken);
      if (response.status === 200) {
        const items = response.data.message.flatMap((group) => group.items);
        const selectedItem = items.find((item) => item.id === itemId);
        if (selectedItem) {
          setItemDetails(selectedItem);
          const relatedItems =
            selectedItem.related_items &&
            Array.isArray(selectedItem.related_items) &&
            selectedItem.related_items.length > 0
              ? selectedItem.related_items[0].map((relatedItem) =>
                  items.find((item) => item.id === relatedItem.id)
                )
              : [];
          setRelatedItems(relatedItems);
        } else {
          console.error("Selected item not found");
        }
      } else {
        console.error("Failed to fetch item details:", response.status);
      }
    } catch (error) {
      console.error("Error fetching item details:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (apiToken) {
      fetchItemDetails();
    }
  }, [apiToken, itemId]);

  useEffect(() => {
    const fetchDetailsOnPageLoad = async () => {
      try {
        const details = await fetchStoreDetails(custom_location);
        setStoreDetails(details);
      } catch (error) {
        console.error("Error fetching store details:", error);
      }
    };

    fetchDetailsOnPageLoad();
  }, [custom_location]);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  const fetchRelatedItemDetails = async (relatedItemId) => {
    try {
      const response = await getItemsGroup(apiToken);
      if (response.status === 200) {
        const items = response.data.message.flatMap((group) => group.items);
        const selectedItem = items.find((item) => item.id === relatedItemId);
        if (selectedItem) {
          setSelectedRelatedItemDetails(selectedItem.related_items[0]);
        } else {
          console.error("Selected related item not found");
        }
      } else {
        console.error("Failed to fetch related item details:", response.status);
      }
    } catch (error) {
      console.error("Error fetching related item details:", error);
    }
  };

  const addToCart = () => {
    let newItem = null;
    if (selectedSubRelatedItem) {
      newItem = selectedSubRelatedItem;
      // Check if main item is in the cart
      const isMainItemInCart = cartItems.some((item) => item.id === itemDetails.id);
      if (!isMainItemInCart) {
        setSelectedSubRelatedItem(null);
        setSelectedRelatedItemId(null);
        toast.error("Please add the main item to the cart before adding related items");
        return;
      }
    } else {
      newItem = itemDetails;
    }
  
    const existingItemIndex = cartItems.findIndex((cartItem) => cartItem.id === newItem.id);
  
    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity += quantity;
      setCartItems(updatedCartItems);
    } else {
      setCartItems([...cartItems, { ...newItem, quantity }]);
    }
  
    // Show the toast message
    toast.success("Item added to the cart successfully");
  
    // Clear the selected sub-related item and related item states
    
  };
  
  


  const increaseCartItemQuantity = (itemId) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === itemId ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCartItems(updatedCartItems);
  };

  const decreaseCartItemQuantity = (itemId) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === itemId
        ? {
            ...item,
            quantity: item.quantity > 1 ? item.quantity - 1 : item.quantity,
          }
        : item
    );
    setCartItems(updatedCartItems.filter((item) => item.quantity > 0));
  };

  const handleRelatedItemClick = (relatedItemId) => {
    setSelectedRelatedItemId(relatedItemId);
    fetchRelatedItemDetails(relatedItemId);
    setSelectedSubRelatedItem(null);
  };

  const handleSubRelatedItemClick = (subRelatedItem) => {
    setSelectedSubRelatedItem(subRelatedItem);
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <>
      <HeaderInnerComp />
      <div className="p-4">
        <div className="container-fluid">
          <section>
            <img src={RestaurantBanner} className="w-100" alt="Restaurant Banner" />
          </section>
          <section>
            <div className="heading-details">
              <h2>{outlet ? outlet.cost_center_name : cost_center_name}</h2>
              <p>{outlet ? outlet.custom_address : custom_address}</p>
              <p className="restaurant-location">
                {outlet ? outlet.custom_location : custom_location}
              </p>
            </div>
            {storeDetails.map((store, index) => (
              <div key={index}>
                <h3>{store.name}</h3>
                <p>Location: {store.custom_location}</p>
                <p>Address: {store.custom_address}</p>
                <p>Cost Center Name: {store.cost_center_name}</p>
              </div>
            ))}
          </section>
          <section className="item-details-wrap">
            {itemDetails && (
              <div className="item-details">
                <div className="item-img">
                  <img src={itemDetails.image || NoImgFood} alt={itemDetails.name} />
                </div>
                <div className="item-desc">
                  <span>
                    <h2>{itemDetails.name}</h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: itemDetails.description,
                      }}
                    ></p>
                  </span>
                  <span className="item-price">
                    <p>
                      £<span>{itemDetails.product_price}</span>
                    </p>
                    {cartItems.some((item) => item.id === itemDetails.id) ? (
                      <div className="quantity">
                        <button onClick={() => decreaseCartItemQuantity(itemDetails.id)}>
                          -
                        </button>
                        <span>
                          {cartItems.find((item) => item.id === itemDetails.id).quantity}
                        </span>
                        <button onClick={() => increaseCartItemQuantity(itemDetails.id)}>
                          +
                        </button>
                      </div>
                    ) : (
                      <button className="btn-add-to-basket" onClick={addToCart}>
                        Add to Basket
                      </button>
                    )}
                  </span>
                </div>
              </div>
            )}
          </section>
          <section className="related-item-list">
            <h3>Related Items</h3>
            <div className="related-items">
              {relatedItems.map((item) => (
                <div
                  className={`related-item-box ${
                    selectedRelatedItemId === item.id ? "selected" : ""
                  }`}
                  key={item.id}
                >
                  <img
                    src={item.image || NoImgFood}
                    alt={item.name}
                    className="rel-item-img"
                    onClick={() => handleRelatedItemClick(item.id)}
                  />
                  <div className="rel-item-desc">
                    <h4>{item.name}</h4>
                    {/* <p>{item.descrption}</p> */}
                    {/* <p className="rel-item-price">£{item.product_price}</p> */}
                  </div>
                </div>
              ))}
            </div>
            {selectedRelatedItemId && (
              <div className="related-item-for">
                <h3>Choice for <span> {selectedRelatedItemId}</span></h3>
                <div className="related-items">
                  {selectedRelatedItemDetails.map((item) => (
                    <div
                      className={`related-item-box ${
                        selectedSubRelatedItem?.id === item.id ? "selected" : ""
                      }`}
                      key={item.id}
                      onClick={() => handleSubRelatedItemClick(item)}
                    >
                      <img
                        src={item.image || NoImgFood}
                        alt={item.name}
                        className="rel-item-img"
                        onError={(e) => {
                          e.target.src = NoImgFood;
                        }}
                      />
                      <div className="rel-item-desc">
                        <h4>{item.name}</h4>
                        <p>{item.descrption}</p>
                        <p className="rel-item-price">£{item.product_price}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </section>
          <div className="bottom-btns">
            <span>
              <button className="btn-back" onClick={handleBackButtonClick}>
                Back
              </button>
            </span>
            <span className="item-price">
              <button
                className="btn-add-to-basket"
                onClick={addToCart}
                // disabled={!selectedSubRelatedItem || !cartItems.some((item) => item.id === itemDetails.id)}
              >
                Add to Basket
              </button>
            </span>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default ItemDetails;
