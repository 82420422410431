import React, { useEffect, useState,useContext } from 'react';
import { Modal, Form, Input, Checkbox, Button, notification, Spin } from 'antd';
import { authenticate, startCheckout } from './isvCheckout';
import { getApitoken } from '../module/landingScreen';
import { paymentRequest } from '../module/payment';
import { createOrder } from '../module/createOrder';
import OrderSuccesspage from '../screens/OrderSuccesspage';


function CustomerDetails({ cartItems, totalPrice, orderRequest }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [ apiToken,setApiToken ] = useState([])
  const [ redirectURL,setRedirectURL ] = useState("");
  const [loading, setLoading] = useState(false);
  const cost_center = localStorage.getItem("costCenter")
  // const { setCheckoutData } = useContext(CheckoutContext);

  const showModal = () => {
    if (cartItems.length === 0) {
      notification.warning({
        message: 'Empty Cart',
        description: 'Please add items to the cart before proceeding to checkout.',
      });
    } else {
      setIsModalVisible(true);
    }
  };
  

  const getAPIToken = async () => {
    const res = await getApitoken();
    if (res.status === 200) {
      setApiToken(res.data.message.data);
    } else {
      console.log("Error in getting the API Token");
    }
  };

  useEffect(() => {
    getAPIToken()
  },[])

  const handleOk = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      const currentDate = new Date();
      
      localStorage.setItem('customerDetails', JSON.stringify(values));
      localStorage.setItem('totalPrice', totalPrice);

      const payload1 = {
        order_list: {
          source:"WEB",
          hub_manager: "hssozi@erpchampions.org",
          transaction_date: currentDate.toISOString().slice(0, 19).replace('T', ' '),
          delivery_date: currentDate.toISOString().split('T')[0],
          items: cartItems.map(item => ({
            item_code: item.id,
            item_name: item.name,
            rate: item.product_price,
            sub_items: [],
            qty: item.quantity,
            ordered_price: item.product_price,
            tax: item.tax,
            estimated_time: item.estimated_time
          })),
          mode_of_payment: "Cash",
          mpesa_No: [],
          tax: [],
          type: "Takeaway",
          email: values.email,
          mobile: values.phoneNumber, 
          name: values.customerName,
          cost_center: cost_center,
          order_request: orderRequest,
        }
      };

      const payload = {
        payment_list : {
            auth_token_url:apiToken.authtoken_url,
            client_id : apiToken.client_id,
            client_secret : apiToken.client_secret,
            base_payment_url : apiToken.base_payment_url,
            customer_email : values.email,
            customer_name : values.customerName,
            customer_phone : values.phone,
            country_code : "GB",
            request_lang : "en-GB",
            amount : totalPrice * 100,
            customer_trans : "payment description",
            checkout_url : apiToken.checkout_url,
            source_code : apiToken.source_code,
            merchant_id : apiToken.merchant_id,
            isv_percentage :apiToken.isv_percentage
            }
        } 

      const api_token = apiToken.api_token
      const res = await createOrder(payload1, api_token);
      if (res.status === 200) {
        const order_id = res.data.message.sales_order.name;
        console.log(res.data.message.sales_order.name)
        localStorage.setItem("OrderID", order_id)
        localStorage.setItem("TransactionDate", currentDate.toISOString().slice(0, 19).replace('T', ' '))
        notification.success({
          message: 'Order Created Successfully',
        });
        const redirectURl = await paymentRequest(payload,api_token);
        console.log(redirectURl.data.message,"Payment Gateway")
        setRedirectURL(redirectURl.data.message);
      
      if (redirectURl.data.message) {
        window.location.href = redirectURl.data.message;
      }
    }
    setLoading(false);
    } catch (error) {
      console.error("Checkout Error: ", error);
      setLoading(false)
      notification.error({
        message: 'Checkout Error',
        description: error.message,
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const validateEmail = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || emailRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Please input a valid email address!');
  };

  // UK phone number validation function
  const validatePhoneNumber = (_, value) => {
    const phoneRegex = /^(0|\+44)\d{10}$/;
    if (!value || phoneRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Please input a valid UK phone number! Add 0 or +44');
  };
  
  const handlePhoneNumberChange = (e) => {
    let { value } = e.target;
  
    if (!/^[0+\d]+$/.test(value)) {
      value = '';
    }
    if (value.startsWith('+44')) {
      value = value.slice(0, 13); 
    } else if (value.startsWith('0')) {
      value = value.slice(0, 11);
    }
  
    form.setFieldsValue({ phoneNumber: value });
  
  };

  return (
    <>
      <Button type="primary" onClick={showModal} className='w-100 checkout-btn'>
        Proceed To CheckOut
      </Button>
      <Modal 
        visible={isModalVisible} 
        onOk={handleOk} 
        onCancel={handleCancel}
        footer={null}
        style={{ width: "50%" }}
      >
        <Form form={form} layout="vertical">
          <h3>Customer Details</h3>
          <Form.Item
            name="customerName"
            rules={[{ required: true, message: 'Please input your name!' }]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { validator: validateEmail }
            ]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[
              { required: true, message: 'Please input your phone number!' },
              { validator: validatePhoneNumber }
            ]}
          >
            <Input placeholder="Enter phone number" onChange={handlePhoneNumberChange}/>
          </Form.Item>
          <Form.Item name="consent" valuePropName="checked">
            <Checkbox>
              I consent to the collection and storage of my personal data, and to receive marketing emails.
            </Checkbox>
          </Form.Item>
          <Form.Item 
            name="iagree" 
            valuePropName="checked"
            rules={[{ required: true, message: 'You must agree to the Terms and Conditions!' }]}
          >
            <Checkbox>
              I agree to the Privacy Policy, <a href='/terms-and-condition' target="_blank" rel="noreferrer">Terms and Conditions</a>
            </Checkbox>
          </Form.Item>
          <Button
            key="submit"
            type="primary"
            onClick={handleOk}
            className='btn-primary submit-btn'
            disabled={loading}
          >
            {loading ? <Spin /> : "Proceed to Pay"} {/* Show loader or button text */}
          </Button>
        </Form>
      </Modal>
    </>
  );
}

export default CustomerDetails;
