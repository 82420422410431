import APIs from "../../api/index";
import axiosInstance from "../../translator";

export const paymentRequest = async (payload,token) => {
    try {
      const res = await axiosInstance.post(APIs.getPayment, payload, {
            headers: {
              Authorization: token,
              'Content-Type': 'application/json',
            },
          });
      return res;
    } catch (error) {
      console.log("Error getting Location", error);
    }
  };

  export const transactionSuccess = async (payload,token) => {
    try {
      const res = await axiosInstance.post(APIs.transaction, payload, {
            headers: {
              Authorization: token,
              'Content-Type': 'application/json',
            },
          });
      return res;
    } catch (error) {
      console.log("Error getting Location", error);
    }
  };

  export const createInvoice = async (payload,token) => {
    try {
      const res = await axiosInstance.post(APIs.createInvoice, payload, {
            headers: {
              Authorization: token,
              'Content-Type': 'application/json',
            },
          });
      return res;
    } catch (error) {
      console.log("Error getting Location", error);
    }
  };