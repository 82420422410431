import React from "react";
import { useNavigate } from "react-router-dom";
import OutletImage from "../assets/images/demoOutlet.png"; // Placeholder image

function Outletcards({ outlet }) {
  const navigate = useNavigate();

  // Ensure the image URL is correct, if not use the placeholder image
  const imageUrl = outlet.custom_attach_image ? outlet.custom_attach_image : OutletImage;

  const handleCardClick = () => {
    localStorage.setItem("costCenter",outlet.name)
    localStorage.removeItem("cartItems")
    navigate(`/restaurantdetails/${outlet.cost_center_name}`, { state: { outlet } });
  };

  return (
    <>
      <div className="card" onClick={handleCardClick}>
        <img
          className="card-img-top"
          style={{
            borderTopRightRadius: "20px",
            borderTopLeftRadius: "20px",
          }}
          src={imageUrl}
          alt={`${outlet.cost_center_name}`}
          onError={(e) => { e.target.onerror = null; e.target.src = OutletImage; }} 
        />
        <div className="card-body">
          <h4 className="card-head">{outlet.cost_center_name}</h4>
          {/* <div className="address">{outlet.custom_address}</div> */}
          <div className="location">{outlet.custom_location}</div>
        </div>
      </div>
    </>
  );
}

export default Outletcards;
