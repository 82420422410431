import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "../index.css"
import App from '../App'
// import Cds from './screens/Cds'
import WebLanding from "../screens/WebLanding"; 
import RestaurantDetails from "../screens/RestaurantDetails";
import RelatableItems from "../components/RelatableItems";
import ItemDetails from "../components/ItemDetails";
import Cds from '../screens/Cds';
import OrderManagement from '../screens/OrderManagement';
import OrderSuccesspage from "../screens/OrderSuccesspage";
import PrivacyPolicy from "../components/PrivacyPolicy";
import TermsAndConditions from "../components/TermsAndConditions";

const Routing = () => {
    // const [isOpen, setIsOpen] = useState(true);
  
    // const toggleSidebar = () => {
    //   setIsOpen(!isOpen);
    // };

    return (
        <>
          <Router>
            <Routes>
              {/* <Route path="/cds" element={<Cds />} /> */}
              <Route path="/" element={<App />} />
              <Route path="/cds" element={<Cds />} />
              <Route path="/kds" element={<OrderManagement />} />
              <Route path="/WebLanding" element={<WebLanding />} /> 
              <Route path="/restaurantdetails/:cost_center_name" element={<RestaurantDetails />} />
              <Route path="/restaurantdetails/:cost_center_name/:itemId" element={<ItemDetails />} />
      
              <Route path="/RelatableItems" element={<RelatableItems />} />
              {/* <Route path="/RestaurantDetails/:id" element={<ItemDetails />} /> */}
              {/* <Route path="/ItemDetails" element={<ItemDetails />} /> */}
              <Route path="/OrderSuccesspage" element={<OrderSuccesspage />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-condition" element={<TermsAndConditions />} />
            </Routes>
            </Router>
        </>
    )}

export default Routing;
