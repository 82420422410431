import React, { useState, useEffect } from "react";
import { Layout, Tabs } from "antd";
import HeaderComponent from "../components/HeaderKDS";
import FooterComponent from "../components/Footer";
import OrderCard from "../components/OrderCard";
import { getApitoken, getKitchenkds } from "../module/kichtenCDS/kitchencds";
import GoogleFontLoader from "react-google-font-loader";
import "../assets/css/styles.css";

const { Content } = Layout;
const { TabPane } = Tabs;

function OrderManagement() {
  const [openOrders, setOpenOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [apiToken, setApiToken] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [finishClickedStates, setFinishClickedStates] = useState({});

  const getAPIToken = async () => {
    const status = activeTab === "1" ? "Open" : "Completed";

    const res = await getApitoken();
    if (res.status === 200) {
      setApiToken(res.data.message.data.api_token);
      const response = await getKitchenkds(status, res.data.message.data.api_token);
      if (response.status === 200) {
        if (status === "Open") {
          setOpenOrders(response.data.message);
        } else {
          setCompletedOrders(response.data.message);
        }
      }
    } else {
      console.log("Error in getting the API Token");
    }
  };

  useEffect(() => {
    getAPIToken();
  }, [activeTab]);

  const handleCompleteOrder = (orderId) => {
    const completedOrder = openOrders.find(order => order.order_id === orderId);
    setCompletedOrders([...completedOrders, completedOrder]);
    setOpenOrders(openOrders.filter(order => order.order_id !== orderId));
    setFinishClickedStates(prevStates => {
      const newState = { ...prevStates };
      delete newState[orderId];
      return newState;
    });
  };

  const handleFinishClicked = (orderId) => {
    setFinishClickedStates(prevStates => ({
      ...prevStates,
      [orderId]: !prevStates[orderId],
    }));
  };

  return (
    <Layout>
      <HeaderComponent welcomeMessage="Good Morning," colorFont="Team" />
      <GoogleFontLoader
        fonts={[
          { font: "ABeeZee", weights: [400, 700] }, // ABeeZee Google Font
          { font: "Nunito", weights: [400, 700], display: "swap" }, // Nunito Google Font
          { font: "Poppins", weights: [400, 700], display: "swap" }, // Poppins Google Font
        ]}
      />

      <Content>
        <Tabs defaultActiveKey="1" activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
          <TabPane tab="Open" key="1">
            <div className="orders-container">
              {openOrders.map((order) => (
                <div className="order-block" key={order.order_id}>
                  <OrderCard
                    order={order}
                    onComplete={() => handleCompleteOrder(order.order_id)}
                    heading={order.type}
                    items={order.items}
                    apiToken={apiToken}
                    finishClicked={!!finishClickedStates[order.order_id]}
                    onFinishClicked={() => handleFinishClicked(order.order_id)}
                  />
                </div>
              ))}
            </div>
          </TabPane>
          <TabPane tab="Completed" key="2">
            <div className="orders-container">
              {completedOrders.map((order) => (
                <div className="order-block" key={order.order_id}>
                  <OrderCard
                    order={order}
                    heading={order.type}
                    items={order.items}
                    apiToken={apiToken}
                    finishClicked={false} // Completed orders should not show Finish button
                  />
                </div>
              ))}
            </div>
          </TabPane>
        </Tabs>
      </Content>
      {/* <FooterComponent /> */}
    </Layout>
  );
}

export default OrderManagement;
