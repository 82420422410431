// HeaderComponent.js
import React, { useEffect, useState } from "react";
import { Layout, Select } from "antd";
import webLogo from "../assets/images/webLogo.svg";
import { useLocation } from "./LocationContext";
import "./index.css";
import "../App.css";
import { getApitoken, getLocation } from "../module/landingScreen"; // Ensure this function is updated as above

const { Header } = Layout;

const HeaderComponent = () => {
  const { selectedLocation, setSelectedLocation } = useLocation();
  const [location, setLocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiToken, setApiToken] = useState(false);


  const handleLocationChange = (value) => {
    setSelectedLocation(value);
  };

  const getAPIToken = async () => {
    try {
      const res = await getApitoken();
      if (res.status === 200) {
        const apiToken = res.data.message.data;
        setApiToken(apiToken);
      } else {
        console.error("Failed to fetch API token:", res.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getAPIToken();
  }, []);

  const fetchLocations = async (searchLocation = "") => {
    setLoading(true);
    try {
      const res = await getLocation(searchLocation);
      if (res && res.status === 200) {
        const locations =
          res.data.message.map((item) => item.custom_location) || [];
        setLocation(locations);
      } else {
        console.error("Failed to get location:", res.status);
      }
    } catch (error) {
      console.error("Error fetching location:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const handleSearch = (value) => {
    fetchLocations(value);
  };

  return (
    <Header className="header">
      <a href="/">
      <img
        src={`https://kleogetposdev.frappe.cloud${apiToken?.web_logo_image}`}
        alt="Logo"
        style={{ height: "50px", marginRight: "10px" }}
      />
      </a>
      <Select
        showSearch
        className="header-dropdowns"
        style={{}}
        placeholder="Select Your City"
        onChange={handleLocationChange}
        onSearch={handleSearch}
        filterOption={false}
        value={selectedLocation || null}
        notFoundContent={loading ? "Loading..." : "No locations found"}
      >
        {/* <Select.Option>Select City</Select.Option> */}
        {location.map((loc) => (
          <Select.Option key={loc} value={loc} className="select-option">
            {loc}
          </Select.Option>
        ))}
      </Select>
    </Header>
  );
};

export default HeaderComponent;
