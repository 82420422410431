import React from "react";
import { Layout } from "antd";
import logo from "../assets/images/logo.svg";
import welcomeLogo from "../assets/images/welcomeLogo.svg";
import GoogleFontLoader from "react-google-font-loader";

const { Header } = Layout;

function HeaderComponent({welcomeMessage, colorFont}) {
  return (
    <Header className="header">
      <GoogleFontLoader
        fonts={[
          { font: "ABeeZee", weights: [400, 700] }, // ABeeZee Google Font
        ]}
      />

      <img
        src={logo}
        alt="Logo"
        style={{ height: "50px", marginRight: "10px" }}
      />
      <p className="welcomeText">
        <img
          src={welcomeLogo}
          alt="Welcome"
          style={{ height: "50px", marginRight: "10px" }}
        />        
        {welcomeMessage}
        <span> {colorFont}</span>
      </p>
    </Header>
  );
}

export default HeaderComponent;
