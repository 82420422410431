import React, { useEffect, useState } from "react";
import { Button, Layout, Spin,notification } from "antd";
import Footer from "../components/Footer";
import webLogo from "../assets/images/webLogo.svg";
import OrderSuccessImage from "../assets/images/ordersuccess.png";
import { getApitoken } from "../module/landingScreen";
import { createInvoice, transactionSuccess } from "../module/payment";
import { useNavigate } from "react-router-dom";
import { getCartDetails, getOrderDetails } from "../module/CartDetails";
import Location from '../assets/images/locationpointer.png'

const { Header } = Layout;

function OrderSuccesspage() {
  const customerDetails = JSON.parse(localStorage.getItem('customerDetails'));
  const totalPrice = localStorage.getItem('totalPrice');
  const orderId = localStorage.getItem('OrderID');
  const TransactionDate = localStorage.getItem('TransactionDate');
  // const cartItems = JSON.parse(localStorage.getItem('cartItems'));
  const searchParams = new URLSearchParams(window.location.search);
  const tValue = searchParams.get('t');

  const navigate = useNavigate();

  const [apiToken, setApiToken] = useState([]);
  const [showPage, setShowPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderDetail, setOrderDetail] = useState([]);
  const [ order,setOrder ] = useState([])

  


  // const cartItems = [
  //   {
  //     name: "Deluxe Crispy Chicken Sandwich Combo Meal",
  //     quantity: 1,
  //     product_price: 300.0,
  //   },
  //   {
  //     name: "Deluxe Crispy Chicken Sandwich Combo Meal",
  //     quantity: 2,
  //     product_price: 600.0,
  //   },
  //   {
  //     name: "Deluxe Crispy Chicken Sandwich Combo Meal",
  //     quantity: 1,
  //     product_price: 300.0,
  //   },
  // ];

  const fetchToken = async () => {
    try {
      const tokenRes = await getApitoken();
      if (tokenRes.status === 200) {
        setApiToken(tokenRes.data.message.data);
        executeTransaction(tokenRes.data.message.data);
        orderDetails(tokenRes.data.message.data)
      } else {
        console.error("Error in getting the API Token");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      setLoading(false);
    }
  };

  const orderDetails = async (tokenData) => {
    try {
      const cartres = await getOrderDetails(orderId,tokenData.api_token);
      if (cartres.status === 200) {
        setOrderDetail(cartres.data.message.item_details);
        localStorage.removeItem("cartItems");
        setOrder(cartres.data.message)
      } else {
        console.error("Error in getting the Order Details");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      setLoading(false);
    }
  };

  const executeTransaction = async (tokenData) => {
    try {
      const paymentList = {
        auth_token_url: tokenData.authtoken_url,
        client_id: tokenData.client_id,
        client_secret: tokenData.client_secret,
        base_payment_url: tokenData.base_payment_url,
        customer_email: customerDetails.email,
        customer_name: customerDetails.customerName,
        customer_phone: customerDetails.phone,
        country_code: "GB",
        request_lang: "en-GB",
        amount: totalPrice * 100,
        customer_trans: "payment description",
        checkout_url: tokenData.checkout_url,
        source_code: tokenData.source_code,
        merchant_id: tokenData.merchant_id,
        isv_percentage: tokenData.isv_percentage
      };
      
      const transactionPayload = {
        merchant_id: tokenData.merchant_id,
        transaction_id: tValue,
        payment_list: paymentList,
      };

      const transactionRes = await transactionSuccess(transactionPayload, tokenData.api_token);
      if (transactionRes.status === 200 && transactionRes.data.message.statusId === "F") {
        setShowPage(true);
        const payload2 = {
          message: {
            status: transactionRes.data.message.statusId,
            order_id: orderId,
            transaction_date: TransactionDate,
          }   
        };
        try {
          const Invoice = await createInvoice(payload2, tokenData.api_token);
          if (Invoice.status === 200) {
            notification.success({
              message: 'Invoice Created Successfully',
            });
          } else {
            notification.error({
              message: 'Invoice Creation Failed',
              description: `Failed to create invoice: ${Invoice.data.message || 'Unknown error'}`,
            });
          }
        } catch (invoiceError) {
          notification.error({
            message: 'Invoice Creation Error',
            description: `Failed to create invoice: ${invoiceError.message || 'Unknown error'}`,
          });
        }
      } else {
        notification.error({
          message: 'Transaction Failed',
          description: `Transaction failed: ${transactionRes.data.message || 'Unknown error'}`,
        });
      }
    } catch (error) {
      console.error("Error executing transaction:", error);
      notification.error({
        message: 'Transaction Execution Error',
        description: `Error executing transaction: ${error.message || 'Unknown error'}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const extractedAddress = order && order.address ? order.address.split(',')[0] : '';

  useEffect(() => {
    fetchToken();
    orderDetails();
  }, []);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" />
        <p>Payment in progress...</p>
      </div>
    );
  }

  return showPage ? (
    <div>
      <Header className="header">
        <img
          src={webLogo}
          alt="Logo"
          style={{ height: "50px", marginRight: "10px" }}
        />
      </Header>
      <div className="container order-success px-4 pb-5">
        <div className="row">
          <div className="col-md-8 orderSuccesscontent">
            <h1>“Thank you for choosing {extractedAddress}!”</h1>
            <p>
              “Your order is being prepared and will be ready in just a few
              minutes!”
            </p>
            <div className="d-flex" >
             <span style={{paddingRight:"4px",}}><img src={Location}></img></span> 
              <p className="address-text">
                {order.address}
              </p>
            </div>
            <img
              src={OrderSuccessImage}
              alt=""
              className="w-50 d-flex justify-content-center mx-auto"
            ></img>
            <Button className="d-flex justify-content-center mx-auto btn-primary continue-btn"
              onClick={() => navigate('/')}
            >
              Continue Ordering
            </Button>
          </div>
          <div className="col-md-4">
            <div className="basket rounded-3 ">
              <div className="d-flex justify-content-between cart-head">
                <h2>Basket</h2>
                <p className="Total-items" style={{color:'#00A5A5'}}>{orderDetail.length} Items</p>
              </div>
              <div className="cart-body">
                <p className="order-id">Order ID #{orderId}</p>
                {orderDetail.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex justify-content-between align-items-start justify-content-between cart-item"
                  >
                    <div className="cart-item-name-price">
                      <p>{item.item_name}</p>
                      <p className="d-flex cart-item-price">
                        £<span>{item.amount.toFixed(2)}</span>
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="w-100 cart-qty">
                        <span className="mx-2">{item.qty}</span>
                      </div>
                    </div>
                  </div>
                ))}
               {order.order_request && (
                  <div className="order-note">
                    <h4>Order Note</h4>
                    <p>{order.order_request}</p>
                  </div>
                )}
                <div className="d-flex justify-content-between cart-total-paid">
                  <p>Total Paid</p>
                  <p className="cart-amount">
                    £<span>{totalPrice}</span>
                  </p>
                </div>
              </div>
              
            </div>
            <div className="border rounded p-3 mt-3">
              <span style={{display:'flex', justifyContent:'space-between', fontSize:'13px', fontWeight:'500',}}><span>Estimated time</span><span>{order.estimated_time} min</span>  </span> 
              <span style={{display:'flex',justifyContent:'space-between', fontSize:'14px'}}><p>Service</p><p style={{color:'#05A7A7'}}>Take away</p></span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', gap:'12px' }}>
      <Spin size="large" />
      <p style={{display:'contents'}}>Payment in progress...</p>
    </div>
  );
}

export default OrderSuccesspage;
