import APIs from "../../api/index";
import axiosInstance from "../../translator";

export const getApitoken = async () => {
  try {
    const res = axiosInstance.get(APIs.token);
    return res;
  } catch (error) {
    console.log("Error while getting the token", error);
    return error.response;
  }
};

export const getLocation = async (searchLocation = "") => {
  try {
    const url = `${APIs.getLocation}?search_location=${searchLocation}`;
    const res = await axiosInstance.get(url);
    return res;
  } catch (error) {
    console.log("Error getting Location", error);
  }
};

export const getRestaurantDetailByLocation = async (custom_location = "") => {
  try {
    const url = `${APIs.getLocation}?custom_location=${custom_location}`;
    const res = await axiosInstance.get(url);
    return res.data;
  } catch (error) {
    console.log("Error getting Location", error);
    throw error; // Rethrow the error to handle it in the calling code
  }
};

export async function fetchStoreDetails(location) {
  const response = await fetch(`${APIs.getLocation}?custom_location=${location}`);
  const data = await response.json();
  return data.message;
}

export const getrestaurantByLocation = async (payload) => {
  try {
    const res = await axiosInstance.post(APIs.getLocation, payload);
    return res;
  } catch (error) {
    console.log("Error getting Location", error);
  }
};

export const getItemsGroup = async (apiToken,name) => {
  try {
    const res = await axiosInstance.get(APIs.getItems, {
      headers: {
        Authorization: `${apiToken}`,
      },
      params: {
        cost_center: name,
      },
    });
    return res;
  } catch (error) {
    console.log("Error getting Items", error);
  }
};

export const getPaymentList = async () => {
  try {
    const res = await axiosInstance.get(APIs.getPaymentList);
    return res.data.payment_list; // Assuming 'payment_list' is the key for payment data
  } catch (error) {
    throw new Error("Error fetching payment list: " + error.message);
  }
};

export const getItemDetails = async (itemId, apiToken) => {
  try {
    const res = await axiosInstance.get(APIs.getItems, {
      headers: {
        Authorization: apiToken,
      },
    });

    if (res.status === 200) {
      const items = res.data.message.flatMap((group) => group.items);
      const item = items.find((item) => item.id === itemId);

      if (item) {
        return { status: 200, data: { message: { data: item } } };
      } else {
        return { status: 404, error: "Item not found" };
      }
    } else {
      return { status: res.status, error: res.statusText };
    }
  } catch (error) {
    return { status: 500, error: error.message };
  }
};
