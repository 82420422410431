import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import webLogo from "../assets/images/webLogo.svg";
import RestaurantBanner from "../assets/images/RestaurantBanner.png";
import CartIcon from "../assets/images/CartIcon.png";
import ItemCard from "../components/ItemCards";
import { getApitoken, getItemsGroup } from "../module/landingScreen";
import Cart from "../components/Cart";
import Footer from "../components/Footer";
import HeaderInnerComp from "../components/Header";

const { Header } = Layout;

function RestaurantDetails() {
  const { cost_center_name, custom_address, custom_location } = useParams();
  const location = useLocation();
  const outlet = location.state?.outlet;

  const [cartItems, setCartItems] = useState(() => {
    const savedCartItems = localStorage.getItem("cartItems");
    return savedCartItems ? JSON.parse(savedCartItems) : [];
  });
  const [apiToken, setApiToken] = useState([]);
  const [items, setItems] = useState([]);
  const [itemGroups, setItemGroups] = useState([]);
  const [selectedItemGroup, setSelectedItemGroup] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  const [banner,setBanner] = useState("")

  const getAPIToken = async () => {
    try {
      const res = await getApitoken();
      if (res.status === 200) {
        const apiToken = res.data.message.data.api_token;
        setApiToken(apiToken);
        setBanner(res.data.message.data)
        const response = await getItemsGroup(apiToken,outlet.name);
        if (response.status === 200) {
          const validGroups = response.data.message.filter(group => group.items && group.items.length > 0);
          const groups = validGroups.map((group) => group.item_group);
          setItems(response.data.message);
          setItemGroups(groups);
          setSelectedItemGroup(groups[0]);
        } else {
          console.error("Failed to fetch item groups:", response.status);
        }
      } else {
        console.error("Failed to fetch API token:", res.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAPIToken();
  }, []);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const addToCart = (item) => {
    const existingItemIndex = cartItems.findIndex(
      (cartItem) => cartItem.id === item.id
    );
    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity += 1;
      setCartItems(updatedCartItems);
    } else {
      setCartItems([...cartItems, { ...item, quantity: 1 }]);
    }
  };

  const increaseQuantity = (itemId) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === itemId ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCartItems(updatedCartItems);
  };

  const decreaseQuantity = (itemId) => {
    const updatedCartItems = cartItems.map((item) =>
      item.id === itemId
        ? {
            ...item,
            quantity: item.quantity > 1 ? item.quantity - 1 : 0,
          }
        : item
    );
    setCartItems(updatedCartItems.filter((item) => item.quantity > 0)); 
  };
  

  const removeFromCart = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems.splice(index, 1);
    setCartItems(updatedCartItems);
  };

  const renderItems = () => {
    const selectedGroup = items.find(
      (group) => group.item_group === selectedItemGroup
    );
    if (selectedGroup) {
      return selectedGroup.items.map((item, index) => (
        <ItemCard
          key={index}
          item={item}
          addToCart={addToCart}
          increaseQuantity={increaseQuantity}
          decreaseQuantity={decreaseQuantity}
          cartItems={cartItems}
          itemLink={`/restaurantdetails/${cost_center_name}/${item.id}`}
          itemState={{
            custom_location: outlet ? outlet.custom_location : custom_location,
          }}
        />
      ));
    } else {
      return null;
    }
  };

  const totalPrice = cartItems
    .reduce((total, item) => {
      const price = parseFloat(item.product_price);
      const quantity = parseInt(item.quantity, 10);
      return total + (isNaN(price) || isNaN(quantity) ? 0 : price * quantity);
    }, 0)
    .toFixed(2);

  return (
    <>
      <HeaderInnerComp />
      <div className="p-4">
        <div className="container-fluid">
          <section>
            <img src={`https://kleogetposdev.frappe.cloud${banner?.web_outlet_details_banner}`} className="w-100" alt="" />
          </section>
          <div className="heading-details">
            <h2>{outlet ? outlet.cost_center_name : cost_center_name}</h2>
            <p>{outlet ? outlet.custom_address : custom_address}</p>
            <p className="restaurant-location">
              {outlet ? outlet.custom_location : custom_location}
            </p>
          </div>
          <div className="row">
            {isMobile ? (
              <div className="col-12">
                <div className="dropdown-wrap">
                  <button
                    className="dropdown-toggle list-group-item list-group-item-action borderless"
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                  >
                    {selectedItemGroup || "Select a category"}
                  </button>
                  {dropdownOpen && (
                    <ul className="dropdown-menu show">
                      {itemGroups.map((group, index) => (
                        <li
                          key={index}
                          className={`dropdown-item ${
                            selectedItemGroup === group ? "active" : ""
                          }`}
                          onClick={() => {
                            setSelectedItemGroup(group);
                            setDropdownOpen(false);
                          }}
                        >
                          {group}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            ) : (
              <div className="col-md-3">
                <div
                  className="list-group restaurant-menu"
                  id="list-tab"
                  role="tablist"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  <div
                    className="scrollable-content"
                    style={{ maxHeight: "300px", overflowY: "auto" }}
                  >
                    {itemGroups
                      .slice(0, itemGroups.length)
                      .map((group, index) => (
                        <li
                          key={index}
                          type="button"
                          className={`list-group-item list-group-item-action borderless ${
                            selectedItemGroup === group ? "active" : ""
                          }`}
                          onClick={() => setSelectedItemGroup(group)}
                        >
                          {group}
                        </li>
                      ))}
                  </div>
                </div>
              </div>
            )}
            <div className="col-xl-6 col-lg-5 col-md-12">{renderItems()}</div>
            {isMobile ? (
              <button
                className="cart-button"
                onClick={() => setIsCartOpen(true)}
              >
                <img src={CartIcon} alt="Cart" />
                {cartItems.length > 0 && (
                  <span className="cart-item-count">{cartItems.length}</span>
                )}
                <span className="total-price">£{totalPrice}</span>
              </button>
            ) : (
              <div className="col-xl-3 col-lg-4 col-md-3">
                <Cart
                  cartItems={cartItems}
                  increaseQuantity={increaseQuantity}
                  decreaseQuantity={decreaseQuantity}
                  removeFromCart={removeFromCart}
                  totalPrice={totalPrice}
                  isMobile={isMobile}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {isMobile && isCartOpen && (
        <Cart
          cartItems={cartItems}
          increaseQuantity={increaseQuantity}
          decreaseQuantity={decreaseQuantity}
          removeFromCart={removeFromCart}
          totalPrice={totalPrice}
          setIsCartOpen={setIsCartOpen}
          isMobile={isMobile}
        />
      )}
      <Footer />
    </>
  );
}

export default RestaurantDetails;
