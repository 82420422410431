import React, { useState } from "react";
import { Card, Button, Modal } from "antd";
import { completedOrder } from "../module/kichtenCDS/kitchencds";

const OrderCard = ({ order, onComplete, heading, items, apiToken, finishClicked, onFinishClicked }) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [orderRequestModalVisible, setOrderRequestModalVisible] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const itemsToShow = expanded ? items.length : 3;

  const finishOrders = async () => {
    const payload = {
      order_status: {
        name: order.order_id,
        status: "Completed",
      },
    };
    const res = await completedOrder(payload, apiToken);
    if (res.status === 200) {
      console.log(res, "Completed");
      const updatedItems = items.filter((item) => item !== selectedItem);
      onComplete(updatedItems);
    }
  };

  const handleOrderComplete = () => {
    if (!finishClicked) {
      onFinishClicked();
    } else {
      finishOrders();
    }
  };

  const handleOrderRequestClick = (item) => {
    setSelectedItem(item);
    setOrderRequestModalVisible(true);
  };

  const handleCloseOrderRequestModal = () => {
    setSelectedItem(null);
    setOrderRequestModalVisible(false);
  };

  const formatTime = (time) => {
    const date = new Date(time);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  return (
    <Card title={heading} className={heading}>
      <div>#{order.order_id} - {order.source}</div>
      <div className="orderTime">
        Order time: {formatTime(order.creation)}
        <span>Estimate: {order.estimated_time} mins</span>
      </div>
      <ul className="Items-list">
        {items.slice(0, itemsToShow).map((item, index) => (
          <li key={index}>
            <span>
              {item.item_name} - {item.qty}
            </span>
            {item.orderRequest && (
              <span
                onClick={() => handleOrderRequestClick(item)}
                style={{
                  color: "red",
                  cursor: "pointer",
                  marginLeft: "10px",
                  fontSize: "12px",
                }}
              >
                Order Request
              </span>
            )}
          </li>
        ))}
        {order.order_request && (
          <li key="order-request" style={{ marginTop: "5px", fontSize: "13px", color: "red", width:'220px' }}>
            Order Request: {order.order_request}
          </li>
        )}
        {items.length > 3 && (
          <a
            onClick={toggleExpanded}
            style={{
              float: "left",
              fontSize: "13px",
              textDecoration: "underline",
              color: "blue",
              ...(expanded ? { paddingTop: "5px" } : { paddingTop: "7px" }),
            }}
          >
            {expanded ? "Show less" : "Show more items"}
          </a>
        )}
      </ul>
      {order.status !== "Completed" && (
        <Button
          type="primary"
          className={`startBtn ${finishClicked ? "finishedBtn" : ""}`}
          onClick={handleOrderComplete}
        >
          {finishClicked ? "Finish" : "Start"}
        </Button>
      )}

      <Modal
        title={`Order Request for ${selectedItem ? selectedItem.item_name : ""}`}
        visible={orderRequestModalVisible}
        onCancel={handleCloseOrderRequestModal}
        footer={[
          <Button key="close" onClick={handleCloseOrderRequestModal}>
            Close
          </Button>,
        ]}
      >
        {selectedItem && <p>{selectedItem.orderRequest}</p>}
      </Modal>
    </Card>
  );
};

export default OrderCard;
