// HeaderComponent.js
import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import webLogo from "../assets/images/webLogo.svg";
import "./index.css";
import "../App.css";
import { getApitoken } from "../module/landingScreen";

const { Header } = Layout;

const HeaderInnerComp = () => {

  const [apiToken, setApiToken] = useState(false);
  const getAPIToken = async () => {
    try {
      const res = await getApitoken();
      if (res.status === 200) {
        const apiToken = res.data.message.data;
        setApiToken(apiToken);
      } else {
        console.error("Failed to fetch API token:", res.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getAPIToken();
  }, []);


  return (
    <Header className="header">
      <a href="/">
        <img
          src={`https://kleogetposdev.frappe.cloud${apiToken?.web_logo_image}`}
          alt="Logo"
          style={{ height: "50px", marginRight: "10px" }}
        />
      </a>
    </Header>
  );
};

export default HeaderInnerComp;
