import React, { useState } from "react";
import RemoveFromCart from "../assets/images/RemoveFromCart.png";
import CartIcon from "../assets/images/CartIcon.png";
import CustomerDetails from "../components/CustomerDetails";

const Cart = ({
  cartItems,
  increaseQuantity,
  decreaseQuantity,
  removeFromCart,
  totalPrice,
  setIsCartOpen,
  isMobile,
}) => {
  const [orderRequest, setOrderRequest] = useState("");

  // Calculate subtotal and total tax
  const subtotal = cartItems.reduce((acc, item) => acc + item.product_price * item.quantity, 0);
  const totalTax = cartItems.reduce((acc, item) => {
    const taxPercentage = item.tax.length > 0 ? parseFloat(item.tax[0].custom_tax_percentage) : 0;
    return acc + (item.product_price * item.quantity * taxPercentage) / 100;
  }, 0);
  const totalWithTax = subtotal + totalTax;

  return (
    <div className={`cart-container ${isMobile ? "cart-popup" : "cart-sidebar"}`}>
      <div className="cart-content">
        <div className="d-flex justify-content-between border-bottom p-2 cart-head">
          <p className="text-black fs-4 fw-semibold">Basket</p>
          {cartItems.length > 0 && (
            <span className="cart-item-count-text" style={{ color: "#00A5A5" }}>
              {cartItems.length} Items
            </span>
          )}
          {isMobile && (
            <button className="btn btn-link text-danger" onClick={() => setIsCartOpen(false)}>
              Close
            </button>
          )}
        </div>
        {cartItems.length === 0 ? (
          <>
            <img src={CartIcon} className="d-flex justify-content-center p-2 mx-auto" alt="" />
            <p className="d-flex justify-content-center" style={{ paddingLeft: "30px" }}>
              No Item In Cart
            </p>
          </>
        ) : (
          <>
            <div
              style={{
                maxHeight: cartItems.length > 4 ? "300px" : "auto",
                overflowY: cartItems.length > 4 ? "scroll" : "visible",
              }}
              className="scrollable-content"
            >
              {cartItems.map((item, index) => (
                <div
                  key={index}
                  className="p-2 border-bottom align-items-center justify-content-between cart-body"
                >
                  <div className="d-flex justify-content-between">
                    <p>{item.name}</p>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="border rounded w-100 quantity-control">
                        <a className="" onClick={() => decreaseQuantity(item.id)}>
                          -
                        </a>
                        <span className="mx-2">{item.quantity}</span>
                        <a className="mr-1" onClick={() => increaseQuantity(item.id)}>
                          +
                        </a>
                      </div>
                      <img src={RemoveFromCart} alt="" onClick={() => removeFromCart(index)}></img>
                    </div>
                  </div>
                  <div className="d-flex">
                    <p className="fw-bold">£</p>
                    <p className="fw-semibold" style={{ color: "#00A5A5", paddingLeft: "3px" }}>
                      {(item.product_price * item.quantity).toFixed(2)}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="order-note mb-4">
              <h6 className="mt-4">Order Request</h6>
              <input
                type="text"
                placeholder="Enter your order request"
                value={orderRequest}
                onChange={(e) => setOrderRequest(e.target.value)}
                className="form-control p-0"
                style={{ border: "none", boxShadow: "none" }}
              />
            </div>
            <div className="p-1 border-top">
              <div className="d-flex justify-content-between mt-2">
                <h6 className="fw-bold">Subtotal</h6>
                <span className="d-flex">
                  <h6 className="fw-bold" style={{ marginRight: "4px" }}>£</h6>
                  <h6 className="fw-bold" style={{color:'#00A5A5'}}>{subtotal.toFixed(2)}</h6>
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="">VAT</h6>
                <span className="d-flex">
                  <h6 style={{ marginRight: "4px" }}>£</h6>
                  <h6 style={{color:'#00A5A5'}}>{totalTax.toFixed(2)}</h6>
                </span>
              </div>
              <div className="d-flex justify-content-between border-top">
                <p className="fw-bold">Total</p>
                <p className="fw-bold d-flex">
                  <p style={{ marginRight: "4px" }}>£</p>
                  <p>{totalWithTax.toFixed(2)}</p>
                </p>
              </div>
            </div>
          </>
        )}
        <div style={{ width: "100%" }}>
          <CustomerDetails
            cartItems={cartItems}
            totalPrice={totalWithTax.toFixed(2)}
            orderRequest={orderRequest}
          />
        </div>
      </div>
      {isMobile && <div className="cart-popup-overlay" onClick={() => setIsCartOpen(false)}></div>}
    </div>
  );
};

export default Cart;
