import React from "react";
import { Link, useParams } from "react-router-dom";
import NoImgFood from "../assets/images/nofood-img-dummy.png";

export default function ItemCard({ item, addToCart, cartItems, increaseQuantity, decreaseQuantity }) {
  const { cost_center_name } = useParams();

  const handleAddToCart = (event) => {
    event.stopPropagation(); // Prevent the onClick event of the parent div from being triggered
    addToCart(item);
  };

  const cartItem = cartItems.find(cartItem => cartItem.id === item.id);

  // Use placeholder image if item.image is not defined
  const itemImage = item.image || NoImgFood;
  

  return (
    <div className="item-card w-100 justify-content-center mb-3">
      <div className="d-flex align-items-stretch card-body">
        <div className="item-card-image" style={{ cursor: "pointer" }}>
          <Link
            to={{
              pathname: `/restaurantdetails/${cost_center_name}/${item.id}`,
              state: { selectedItem: item },
            }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <img src={itemImage} alt="Item" />
          </Link>
        </div>
        <div className="item-card-main">
          <div className="card-text">
            <Link
              to={{
                pathname: `/restaurantdetails/${cost_center_name}/${item.id}`,
                state: { selectedItem: item },
              }}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <h4 className="card-title">{item.name}</h4>
            </Link>
            <p dangerouslySetInnerHTML={{ __html: item.description }}></p>
            <div className="allergens-icons">
              {item.allergens && item.allergens[0].map((allergen, index) => (
                <img
                  key={index}
                  src={allergen.icon}
                  alt={allergen.allergens}
                  title={allergen.allergens}
                  style={{ width: "24px", height: "24px", marginRight: "5px" }}
                />
              ))}
            </div>
          </div>
          <div className="item-card-price">
            <p>
              £ <span>{item.product_price}</span>
            </p>
            {cartItem ? (
              <div className="quantity-controls">
                <button onClick={(event) => { event.stopPropagation(); decreaseQuantity(item.id); }}>-</button>
                <span>{cartItem.quantity}</span>
                <button onClick={(event) => { event.stopPropagation(); increaseQuantity(item.id); }}>+</button>
              </div>
            ) : (
              <button onClick={handleAddToCart}>+ Add</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
